import { Endpoints, createEndpointsConfig } from "./endpoints"
import { PageSettings } from "./pageSettings"
import { SessionConfig } from "./sessionConfig"
import { SiteOwnerSettings, createSiteOwnerSettings } from "./siteOwnerSettings"

export type Geo3 = {
  /**
   * Continent
   */
  con: string
  /**
   * Country
   */
  c: string
  /**
   * State
   */
  s: string
  /**
   * ASN
   */
  a: number
}

export type ConfigSettings3 = {
  /**
   * App events interval in seconds
   */
  a: number
  /**
   * Config refresh interval in seconds
   */
  c: number
  /**
   * DNS client info base hostname
   */
  dcibh: string
  /**
   * DNS client info sample percentage. 0 means never. 1 means always.
   */
  dcisp: number
  /**
   * Max initial measurements
   */
  m: number
  /**
   * Disallowed networks
   */
  n: number[]
  /**
   * Scheduler run interval in seconds
   */
  sc: number
  /**
   * Start delay in seconds
   */
  st: number
}

export type TestObject3 = {
  /**
   * Add random subdomain token
   */
  a: boolean
  /**
   * Platform ID
   */
  i: string
  /**
   * Pulsar App ID
   */
  pa: string | null
  /**
   * Pulsar Job ID
   */
  pj: string | null
  /**
   * Send to Doppler
   */
  d: boolean
  /**
   * Send to Pulsar
   */
  p: boolean
  /**
   * Size in bytes
   */
  s: number
  /**
   * Target frequency (measurements per hour)
   */
  f: number
  /**
   * Timeout in seconds
   */
  t: number
  /**
   * Test object URL
   */
  u: string
  /**
   * Used for decisioning
   */
  us: boolean
}

export type Config3Source = {
  /**
   * Geo settings
   */
  g: Geo3
  /**
   * Array of test object settings
   */
  o: TestObject3[]
  /**
   * Global settings
   */
  s: ConfigSettings3
  /**
   * Response updated at
   */
  u: string
  /**
   * Config version
   */
  v: string
}

export const createConfig3: (
  source: Config3Source,
  pageSettings: PageSettings,
) => SessionConfig = (s, p) => new Config3Impl(s, p)

class Config3Impl implements SessionConfig {
  private _clientEndpoints: Endpoints
  private _siteOwnerSettings: SiteOwnerSettings
  constructor(source: Config3Source, pageSettings: PageSettings) {
    /* eslint-disable @typescript-eslint/dot-notation */
    const settings = source["s"]
    this._siteOwnerSettings = createSiteOwnerSettings(settings, pageSettings)
    /* eslint-enable @typescript-eslint/dot-notation */
    this._clientEndpoints = createEndpointsConfig(
      {
        application_events_base: "https://doppler-client-events.cbsivideo.com",
        doppler: {
          resource_timing: "https://doppler-beacon.cbsivideo.com/log",
        },
        pulsar: "https://b.ns1p.net?v=1583182801",
      },
      pageSettings,
    )
  }

  get siteOwnerSettings() {
    return this._siteOwnerSettings
  }

  get beaconEndpointInfo() {
    return this._clientEndpoints
  }
}
