import {
  ConsoleOutputLevel,
  DepID_ApplicationEventsBeacon,
  EpochDelta,
  OutputToConsoleFunc,
} from "../@types"
import { encode as messagePackEncode } from "../vendor/msgpack/encode"
import { PlatformTestObject } from "./platformTestObject"
import { RuntimeValues } from "./runtimeValues"
export type Dependencies = {
  callSendBeaconWithData: (
    depID: number,
    url: string,
    data: BodyInit,
  ) => boolean
  outputToConsole: OutputToConsoleFunc
  runtimeValues: RuntimeValues
}

type BeaconPayloadFormat = 1
/**
 * Always 1. See the `DopplerClientType` enum in
 * https://github.com/cbsinteractive/vde-doppler-client-protobuf
 * /blob/main/protos/storage/client_events.proto
 */
type DopplerClientType = 1
type ClientVersionString = string
export type ErrorMessage = string
/**
 * The wall-clock time it took to for the event to complete
 */
export type EventDuration = number
export type GeoContinentCode = string | null
export type GeoCountryCode = string | null
export type GeoCountrySubdivCode = string | null
export type GeoASNumber = number | null
type DopplerPlatformID = string
type TestObjectSize = number

export type ClientInitialized = [
  EpochDelta,
  GeoContinentCode,
  GeoCountryCode,
  GeoCountrySubdivCode,
  GeoASNumber,
]
type ClientInitializedOption = ClientInitialized | null
export type MeasurementBeaconInitiated = [
  EpochDelta,
  GeoContinentCode,
  GeoCountryCode,
  GeoCountrySubdivCode,
  GeoASNumber,
]
type MeasurementBeaconInitiatedList = MeasurementBeaconInitiated[] | null
type MeasurementBeaconCompleted = [
  EpochDelta,
  GeoContinentCode,
  GeoCountryCode,
  GeoCountrySubdivCode,
  GeoASNumber,
  EventDuration,
]
type MeasurementBeaconCompletedList = MeasurementBeaconCompleted[] | null
export type MeasurementBeaconError = [
  EpochDelta,
  GeoContinentCode,
  GeoCountryCode,
  GeoCountrySubdivCode,
  GeoASNumber,
  ErrorMessage,
]
type MeasurementBeaconErrorList = MeasurementBeaconError[] | null
export type SessionConfigDownloadInitiated = [
  EpochDelta,
  GeoContinentCode,
  GeoCountryCode,
  GeoCountrySubdivCode,
  GeoASNumber,
]
type SessionConfigDownloadInitiatedList =
  | SessionConfigDownloadInitiated[]
  | null
type SessionConfigDownloadCompleted = [
  EpochDelta,
  GeoContinentCode,
  GeoCountryCode,
  GeoCountrySubdivCode,
  GeoASNumber,
  EventDuration,
]
type SessionConfigDownloadCompletedList =
  | SessionConfigDownloadCompleted[]
  | null
type SessionConfigDownloadError = [
  EpochDelta,
  GeoContinentCode,
  GeoCountryCode,
  GeoCountrySubdivCode,
  GeoASNumber,
  ErrorMessage,
]
type SessionConfigDownloadErrorList = SessionConfigDownloadError[] | null
type TestObjectDownloadInitiated = [
  EpochDelta,
  GeoContinentCode,
  GeoCountryCode,
  GeoCountrySubdivCode,
  GeoASNumber,
  DopplerPlatformID,
  TestObjectSize,
]
type TestObjectDownloadInitiatedList = TestObjectDownloadInitiated[] | null
type TestObjectDownloadCompleted = [
  EpochDelta,
  GeoContinentCode,
  GeoCountryCode,
  GeoCountrySubdivCode,
  GeoASNumber,
  DopplerPlatformID,
  TestObjectSize,
  EventDuration,
]
type TestObjectDownloadCompletedList = TestObjectDownloadCompleted[] | null
type BeaconPayload = [
  BeaconPayloadFormat,
  DopplerClientType,
  ClientVersionString,
  TestObjectDownloadInitiatedList,
  TestObjectDownloadCompletedList,
  MeasurementBeaconInitiatedList,
  MeasurementBeaconCompletedList,
  MeasurementBeaconErrorList,
  SessionConfigDownloadInitiatedList,
  SessionConfigDownloadCompletedList,
  SessionConfigDownloadErrorList,
  ClientInitializedOption,
]
export type RecordEvent = (
  epochTimestamp: EpochDelta,
  runtimeValues: RuntimeValues,
) => void
export type RecordErrorEvent = (
  epochTimestamp: EpochDelta,
  runtimeValues: RuntimeValues,
  errorMessage: ErrorMessage,
) => void
export type RecordBeforeSessionConfigDownloadEvent = (
  epochTimestamp: EpochDelta,
  runtimeValues: RuntimeValues,
) => SessionConfigDownloadInitiated
export type RecordClientInitializedEvent = (
  epochTimestamp: EpochDelta,
) => ClientInitialized
export type RecordTestObjectEvent = (
  epochTimestamp: EpochDelta,
  runtimeValues: RuntimeValues,
  testObjectConfig: PlatformTestObject,
) => void
export type RecordTimedEvent = (
  epochTimestamp: EpochDelta,
  runtimeValues: RuntimeValues,
  duration: EventDuration,
) => void
export type RecordTimedTestObjectEvent = (
  epochTimestamp: EpochDelta,
  runtimeValues: RuntimeValues,
  testObjectConfig: PlatformTestObject,
  duration: EventDuration,
) => void
export interface ApplicationEvents {
  applicationEventLoggingEndpointBase: string | null
  beacon(): void
  recordAfterMeasurementBeaconEvent: RecordTimedEvent
  recordAfterSessionConfigDownloadEvent: RecordTimedEvent
  recordAfterTestObjectDownloadEvent: RecordTimedTestObjectEvent
  recordBeforeMeasurementBeaconEvent: RecordEvent
  recordBeforeSessionConfigDownloadEvent: RecordBeforeSessionConfigDownloadEvent
  recordBeforeTestObjectDownloadEvent: RecordTestObjectEvent
  recordClientInitializedEvent: RecordClientInitializedEvent
  recordMeasurementBeaconErrorEvent: RecordErrorEvent
  recordSessionConfigDownloadErrorEvent: RecordErrorEvent
}

export const createApplicationEvents: (
  dependencies: Dependencies,
  clientVersion: ClientVersionString,
) => ApplicationEvents = (d, v) => new ApplicationEventsImpl(d, v)

class ApplicationEventsImpl implements ApplicationEvents {
  // Prefer assignment in constructor to avoid compiler using
  // Object.defineProperty, which breaks property munging
  private _applicationEventLoggingEndpointBase
  private _afterMeasurementBeaconEvents
  private _afterSessionConfigDownloadEvents
  private _afterTestObjectDownloadEvents
  private _beforeMeasurementBeaconEvents
  private _beforeSessionConfigDownloadEvents
  private _beforeTestObjectDownloadEvents
  private _clientInitializedEvent
  private _measurementBeaconErrorEvents
  private _sessionConfigDownloadErrorEvents

  private _clientInitializationRecorded = false

  constructor(
    private _dependencies: Dependencies,
    private _clientVersion: ClientVersionString,
  ) {
    this._applicationEventLoggingEndpointBase = null as string | null
    this._afterMeasurementBeaconEvents = [] as MeasurementBeaconCompleted[]
    this._afterSessionConfigDownloadEvents =
      [] as SessionConfigDownloadCompleted[]
    this._afterTestObjectDownloadEvents = [] as TestObjectDownloadCompleted[]
    this._beforeMeasurementBeaconEvents = [] as MeasurementBeaconInitiated[]
    this._beforeSessionConfigDownloadEvents =
      [] as SessionConfigDownloadInitiated[]
    this._beforeTestObjectDownloadEvents = [] as TestObjectDownloadInitiated[]
    this._clientInitializedEvent = null as ClientInitialized | null
    this._measurementBeaconErrorEvents = [] as MeasurementBeaconError[]
    this._sessionConfigDownloadErrorEvents = [] as SessionConfigDownloadError[]
  }

  private _resetEventState() {
    this._afterMeasurementBeaconEvents = []
    this._afterSessionConfigDownloadEvents = []
    this._afterTestObjectDownloadEvents = []
    this._beforeMeasurementBeaconEvents = []
    this._beforeSessionConfigDownloadEvents = []
    this._beforeTestObjectDownloadEvents = []
    this._clientInitializedEvent = null
    this._measurementBeaconErrorEvents = []
    this._sessionConfigDownloadErrorEvents = []
  }

  get applicationEventLoggingEndpointBase() {
    return this._applicationEventLoggingEndpointBase
  }

  set applicationEventLoggingEndpointBase(value: string | null) {
    this._applicationEventLoggingEndpointBase = value
  }

  beacon() {
    const isNonZeroLoggingInterval = () =>
      typeof this._dependencies.runtimeValues
        .applicationEventsBeaconIntervalInMilliseconds === "number"
        ? 0 <
          this._dependencies.runtimeValues
            .applicationEventsBeaconIntervalInMilliseconds
        : false
    // Go through the motions even if the client isn't configured for
    // logging to free memory.
    const reportableEventCount =
      this._afterMeasurementBeaconEvents.length +
      this._afterSessionConfigDownloadEvents.length +
      this._afterTestObjectDownloadEvents.length +
      this._beforeMeasurementBeaconEvents.length +
      this._beforeSessionConfigDownloadEvents.length +
      this._beforeTestObjectDownloadEvents.length +
      (this._clientInitializedEvent ? 1 : 0) +
      this._measurementBeaconErrorEvents.length +
      this._sessionConfigDownloadErrorEvents.length
    if (reportableEventCount) {
      if (
        this._applicationEventLoggingEndpointBase &&
        isNonZeroLoggingInterval()
      ) {
        const payload: BeaconPayload = [
          1, // payload version
          1, // Doppler client type (always 1 for Doppler.js)
          this._clientVersion,
          this._beforeTestObjectDownloadEvents,
          this._afterTestObjectDownloadEvents,
          this._beforeMeasurementBeaconEvents,
          this._afterMeasurementBeaconEvents,
          this._measurementBeaconErrorEvents,
          this._beforeSessionConfigDownloadEvents,
          this._afterSessionConfigDownloadEvents,
          this._sessionConfigDownloadErrorEvents,
          this._clientInitializedEvent,
        ]
        this._dependencies.outputToConsole(
          ConsoleOutputLevel.debug,
          () => `Client event data: ${JSON.stringify(payload)}`,
        )
        this._dependencies.callSendBeaconWithData(
          DepID_ApplicationEventsBeacon,
          new URL("/v1", this._applicationEventLoggingEndpointBase).href,
          messagePackEncode(payload),
        )
      }
      this._resetEventState()
    }
  }

  recordAfterMeasurementBeaconEvent(
    epochTimestamp: number,
    runtimeValues: RuntimeValues,
    duration: EventDuration,
  ): void {
    this._afterMeasurementBeaconEvents.push([
      epochTimestamp,
      ...geoHeaderValues(runtimeValues),
      duration,
    ])
  }

  recordAfterSessionConfigDownloadEvent(
    epochTimestamp: number,
    runtimeValues: RuntimeValues,
    duration: EventDuration,
  ): void {
    this._afterSessionConfigDownloadEvents.push([
      epochTimestamp,
      ...geoHeaderValues(runtimeValues),
      duration,
    ])
  }

  recordAfterTestObjectDownloadEvent(
    epochTimestamp: number,
    runtimeValues: RuntimeValues,
    testObjectConfig: PlatformTestObject,
    duration: number,
  ): void {
    this._afterTestObjectDownloadEvents.push([
      epochTimestamp,
      ...geoHeaderValues(runtimeValues),
      ...platformTestObjectValues(testObjectConfig),
      duration,
    ])
  }

  recordBeforeMeasurementBeaconEvent(
    epochTimestamp: EpochDelta,
    runtimeValues: RuntimeValues,
  ) {
    this._beforeMeasurementBeaconEvents.push([
      epochTimestamp,
      ...geoHeaderValues(runtimeValues),
    ])
  }

  recordBeforeSessionConfigDownloadEvent(
    epochTimestamp: EpochDelta,
    runtimeValues: RuntimeValues,
  ) {
    const result: SessionConfigDownloadInitiated = [
      epochTimestamp,
      ...geoHeaderValues(runtimeValues),
    ]
    this._beforeSessionConfigDownloadEvents.push(result)
    return result
  }

  recordBeforeTestObjectDownloadEvent(
    epochTimestamp: EpochDelta,
    runtimeValues: RuntimeValues,
    testObjectConfig: PlatformTestObject,
  ) {
    this._beforeTestObjectDownloadEvents.push([
      epochTimestamp,
      ...geoHeaderValues(runtimeValues),
      ...platformTestObjectValues(testObjectConfig),
    ])
  }

  recordClientInitializedEvent(epochTimestamp: EpochDelta) {
    if (!this._clientInitializationRecorded) {
      this._clientInitializationRecorded = true
      // Geo isn't known when the event is first recorded. It will
      // be updated after receiving the initial session config response.
      const result: ClientInitialized = [epochTimestamp, "", "", "", null]
      this._clientInitializedEvent = result
      return result
    }
    throw new Error(
      "Only one call to recordClientInitializedEvent should be made",
    )
  }

  recordMeasurementBeaconErrorEvent(
    epochTimestamp: number,
    runtimeValues: RuntimeValues,
    errorMessage: string,
  ): void {
    this._measurementBeaconErrorEvents.push([
      epochTimestamp,
      ...geoHeaderValues(runtimeValues),
      errorMessage,
    ])
  }

  recordSessionConfigDownloadErrorEvent(
    epochTimestamp: number,
    runtimeValues: RuntimeValues,
    errorMessage: string,
  ): void {
    this._sessionConfigDownloadErrorEvents.push([
      epochTimestamp,
      ...geoHeaderValues(runtimeValues),
      errorMessage,
    ])
  }
}

export const geoHeaderValues = (runtimeValues: RuntimeValues) => {
  const asn = parseInt(runtimeValues.lastReadSessConfHeaderAsn || "", 10)
  const result: [
    GeoContinentCode,
    GeoCountryCode,
    GeoCountrySubdivCode,
    GeoASNumber,
  ] = [
    runtimeValues.lastReadSessConfHeaderContinent || null,
    runtimeValues.lastReadSessConfHeaderCountry || null,
    runtimeValues.lastReadSessConfHeaderState || null,
    isNaN(asn) ? null : asn,
  ]
  return result
}

const platformTestObjectValues = (testObjectConfig: PlatformTestObject) => {
  const result: [string, number] = [
    testObjectConfig.dopplerPlatformID,
    testObjectConfig.testObjectSize,
  ]
  return result
}
